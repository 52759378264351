<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	import Popper from 'vue-popperjs';
	import 'vue-popperjs/dist/vue-popper.css';

	export default {
		extends: BaseVue,
		data() {
			return {
				hero:[],
				management_large:[],
				management_small:[],
				static_history:[],
				cities:{},
				citiesMobile:{},
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 500)
			$(document).ready(() => { 
				AOS.init()
			})

			this.apiGetStaticHero()
			this.getManagementLarge()
			this.getManagementSmall()
			this.apiGetStaticContent()
			this.getCities()
			if(this.$route.query.section){
				setTimeout(() => {
					$('html, body').animate({
				        scrollTop: $('#section-city').offset().top - 20
				    }, 'slow');
				}, 1000)
			}
		},
		methods: {
			async getStaticHero(id){
					return (await Gen.apirest('/static-hero?id='+id, {}, ()=>{})).data
			},
			async getStaticContent(id){
					return (await Gen.apirest('/static-content?id='+id, {}, ()=>{})).data
			},
			async apiGetStaticHero(){
					this.hero = await this.getStaticHero(3)
					setTimeout(() => { SEMICOLON.documentOnLoad.init() }, 50);
			},
			async apiGetStaticContent(){
					this.static_history = await this.getStaticContent(4)
			},
			getManagementLarge() {
				Gen.apirest("/management-large-content",{},(err,resp)=>{
					if(err) console.log(err)
					this.management_large = resp.data
				})
			},
			getManagementSmall() {
				Gen.apirest("/management-small-content",{},(err,resp)=>{
					if(err) console.log(err)
					this.management_small = resp.data
					setTimeout(() => { SEMICOLON.widget.carousel(); }, 300)
				})
			},
			getCities() {
				Gen.apirest("/get-cities",{},(err,resp)=>{
					if(err) console.log(err)
					this.cities = resp.data
					this.citiesMobile = resp.dataMobile
					setTimeout(() => { SEMICOLON.widget.extras(); }, 300)
				})
			},
		},
		watch:{
			'$route.query'(v){
				if(this.$route.query.section){
					$('html, body').animate({
				        scrollTop: $('#section-city').offset().top - 20
				    }, 'slow');
				}
			},
		}
	};
</script>
<template>
	<div>
	<section id="slider" class="slider-element boxed-slider notoppadding">
	    <div class="clearfix">
	        <div class="fslider" data-touch="false" data-easing="easeInQuad">
	            <div class="flexslider">
	                <div class="slider-wrap">
						<div v-if="!isMobile" class="slide" :data-thumb="uploader(hero.img_desktop)">
							<img :src="uploader(hero.img_desktop)" :alt="hero.title" :title="hero.title">
							<div class="flex-caption slider-caption-bg">
								<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';font-size:'+hero.ash_title_size+'px;'">{{ hero.title }}</h2>
								<p :style="'font-size:'+hero.ash_sub_title_size+'px;color:'+hero.ash_sub_title_color+';font-family:'+hero.ash_sub_title_font_type+';'" v-if="hero.sub_title">{{hero.sub_title}}</p>
							</div>
						</div>
						<div v-else class="slide" :data-thumb="uploader(hero.img_mobile)">
							<img :src="uploader(hero.img_mobile)" :alt="hero.title" :title="hero.title">
							<div class="flex-caption slider-caption-bg">
								<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';'">{{ hero.title }}</h2>
							</div>
						</div>
					</div>
	            </div>
	        </div>
	    </div>
	</section>
	<section id="content" style="background-color: #000; overflow: visible">
	    <div class="content-wrap pt-0 pb-0">
	        <div id="section-about" class="section page-section bg_white bor_section nobottommargin notopmargin clearfix">
	            <div class="container clearfix">
	                <div class="row justify-content-around dark">
	                    <div class="col-md-12">
	                        <div class="heading_section_light">
	                            <h2>{{static_history.title}}</h2>
	                        </div>
	                    </div>
	                    <div class="col-md-12" data-aos="fade-up" data-aos-duration="1000">
                            <div class="about_wrap">
                            	<div v-html="static_history.description"></div>
                            </div>
                        </div>
	                </div>
	            </div>
	        </div>
	        
	        <div class="clear"></div>
	        <div id="section-speakers" class="section notopmargin nobottommargin page-section  bg_white bor_section clearfix">
	            <div class="container">
	                <div class="row justify-content-around dark">
	                    <div class="col-md-12">
	                        <div class="heading_section">
	                            <h2>{{ web.about_who }}</h2>
	                        </div>
	                    </div>
	                </div>
	                <div class="row justify-content-center">
	                    <div class="col-md-3 col-6 text-center" v-for="(v,k) in management_large" :key="k">
	                        <div class="team_wrap">
	                            <div class="team_wrap_thumb">
	                            	<VImg :src="uploader(v.am_image)" :alt="v.am_name" :title="v.am_name" ></VImg>
	                            </div>
	                            <div class="team_wrap_content">
	                                <h3>{{ v.am_name }}</h3>
	                                <p v-html="v.am_position"></p>
	                                <p class="mt-2">
	                                    <a v-if="v.am_mail" :href="'mailto:'+v.am_mail" target="_blank" class="social-icon si-dark  si-small si-borderless si-rounded si-facebook">
	                                        <i class="icon-line2-envelope"></i>
	                                        <i class="icon-line2-envelope"></i>
	                                    </a>
	                                    <a v-if="v.am_wa" :href="'https://api.whatsapp.com/send?phone=62'+v.am_wa" target="_blank" class="social-icon si-small si-dark si-borderless  si-rounded si-spotify">
	                                        <i class="icon-whatsapp si_16"></i>
	                                        <i class="icon-whatsapp si_16"></i>
	                                    </a>
	                                </p>
	                            </div>
	                        </div>
	                    </div>
	                </div>

	                <div class="row justify-content-center">
	                    <div class="col-md-3 col-6 text-center"></div>
	                    <div class="col-md-3 col-6 text-center"></div>
	                    <div class="col-md-3 col-6 text-center"></div>
	                    <div class="col-md-3 col-6 text-center"></div>
	                </div>
	                <div class="row justify-content-center">
	                    <div class="col-md-3 col-6 text-center"></div>
	                    <div class="col-md-3 col-6 text-center"></div>
	                    <div class="col-md-3 col-6 text-center"></div>
	                </div>
	                <div class="row">
	                    <div class="col-md-12">
	                        <div id="oc-clients" class="owl-carousel image-carousel carousel-widget"
                                data-margin="30" data-loop="true" data-nav="false" data-autoplay="5000"
                                data-pagi="false" data-items-xs="2" data-items-sm="3" data-items-md="4"
                                data-items-lg="5" data-items-xl="6">
	                            <div class="oc-item" v-for="(v2,k2) in management_small" :key="k2">
	                                <div class="team_wrap">
	                                    <div class="team_wrap_thumb img_custom">
	                                    	<VImg :src="uploader(v2.am_image)" :alt="v2.am_name" :title="v2.am_name" ></VImg>
	                                    </div>
	                                    <div class="team_wrap_content">
	                                        <div class="twc_desc">
												<h3>{{ v2.am_name }}</h3>
	                                			<p v-html="v2.am_position"></p>
											</div>
	                                        <p class="mt-2">
	                                            <a v-if="v2.am_mail" :href="'mailto:'+v2.am_mail" target="_blank" class="social-icon si-dark  si-small si-borderless si-rounded si-facebook">
	                                                <i class="icon-line2-envelope"></i>
	                                                <i class="icon-line2-envelope"></i>
	                                            </a>
	                                            <a v-if="v2.am_wa" :href="'https://api.whatsapp.com/send?phone=62'+v2.am_wa" target="_blank" class="social-icon si-small si-dark si-borderless  si-rounded si-spotify">
	                                                <i class="icon-whatsapp si_16"></i>
	                                                <i class="icon-whatsapp si_16"></i>
	                                            </a>
	                                        </p>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	        <div id="section-city" class="section page-section  bg_white bor_section nobottommargin notopmargin clearfix">
	            <div class="container clearfix">
	                <div class="row justify-content-around dark">
	                    <div class="col-md-12">
	                        <div class="heading_section_light">
	                            <h2>{{web.mn_dimatique_cities}}</h2>
	                        </div>
	                    </div>
	                    <div class="col-md-12" data-aos="fade-up" data-aos-duration="1000">
	                        <div class="map_indo"> 
	                        	<img :src="assets('fo_images','indo_map.png')" alt="Map Indonesia" title="Map Indonesia">
	                            <a v-for="(v,k) in cities" :key="k" class="point_map a1" data-trigger="hover" data-toggle="popover"
	                                data-placement="top" :title="v.name" :data-content="v.address" href="" :style="'top:' +v.point_y+'%;left:'+v.point_x+'%;z-index:999;'"> </a>
	                        </div>
	                    </div>
						<div class="col-12" v-if="isMobile">
							<div class="city-list" v-for="(v,k) in citiesMobile" :key="k" data-aos="fade-up" data-aos-duration="1000">
								<h4 class="cl-title">{{v.name}}</h4>
								<ul class="city_mobile mb-0">
									<li v-for="(i,ik) in v.items" :key="ik">
										{{i.ac_address}}
									</li>
								</ul>
							</div>
						</div>
	                </div>
	            </div>
	        </div>
	        <div class="clear"></div>
	    </div>
	</section>
	</div>
</template>

